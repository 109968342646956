import React from "react";
import PropTypes from "prop-types";
import Header from "../../stories/Header";
import { useCart } from "../../services/cart";
import { useFave } from "../../services/fave";
import size from "lodash/size";
import { useWindowSize } from ".././../hooks/useWindowSize";

const HeaderWrapper = ({ headerProps, onSearchClick }) => {
  const { cart, setShowCart } = useCart();
  const { totalItems } = useFave();
  const { width } = useWindowSize();
  const navData = [
    {
      heading: "Engagement",
      subSections: [
        {
          subHeading: "",
          subSection: width < 768 ? 1 : 2,
          links: [
            {
              title: "Start with a diamond",
              link: "/diamonds/",
            },
            {
              title: "Try at home",
              link: "/try-at-home/",
            },
            {
              title: "Start with a setting",
              link: "/engagement-rings/",
            },
            {
              title: "Book an Appointment",
              link: "/consultation/?scroll_target=consultation-form-section",
            },
            {
              title: "Take the ring quiz",
              link: "https://frankdarling.typeform.com/to/SwTTd2?typeform-source=frankdarling.com",
              target: "_blank",
            },
          ],
        },
        {
          subHeading: "By Shape",
          viewAll: "/engagement-rings/",
          subSection: width < 768 ? 2 : width < 1536 ? 3 : 4,
          links: [
            {
              title: "Round",
              link: "/engagement-rings/round-cut",
            },
            {
              title: "Oval",
              link: "/engagement-rings/oval-engagement-rings",
            },
            {
              title: "Emerald",
              link: "/engagement-rings/emerald-cut",
            },
            {
              title: "Cushion",
              link: "/engagement-rings/cushion-cut-engagement-rings",
            },
            {
              title: "Asscher",
              link: "/engagement-rings/asscher-cut-engagement-rings",
            },
            {
              title: "Radiant",
              link: "/engagement-rings/radiant-cut-engagement-rings",
            },
            {
              title: "Pear",
              link: "/engagement-rings/pear-shaped-engagement-rings",
            },
            {
              title: "Marquise",
              link: "/engagement-rings/marquise-cut-engagement-rings",
            },
            {
              title: "Princess",
              link: "/engagement-rings/princess-cut",
            },
            {
              title: "Rose-Cut",
              link: "/engagement-rings/?shape=Rose Cut",
            },
            {
              title: "Old-European",
              link: "/engagement-rings/?shape=Old European",
            },
            {
              title: "Old-Mine",
              link: "/engagement-rings/?shape=Old Mine",
            },
            {
              title: "Trillion",
              link: "/engagement-rings/?shape=Trillion",
            },
          ],
        },
        {
          subHeading: "By Style",
          viewAll: "/engagement-rings/",
          subSection: width < 1200 ? 2 : 3,
          links: [
            {
              title: "Solitaire",
              link: "/engagement-rings/?style=Solitaire",
            },
            {
              title: "Vintage Inspired",
              link: "/engagement-rings/?style=Vintage Inspired",
            },
            {
              title: "Bezel",
              link: "/engagement-rings/?style=Bezel",
            },
            {
              title: "Cathedral",
              link: "/engagement-rings/?style=Cathedral",
            },
            {
              title: "Hidden Halo",
              link: "/engagement-rings/?style=Hidden Halo",
            },
            {
              title: "Diamond Details",
              link: "/engagement-rings/?style=Diamond Details",
            },
            {
              title: "Three Stone",
              link: "/engagement-rings/?style=Three Stone",
            },
            {
              title: "Two Tone",
              link: "/engagement-rings/?style=Two Tone",
            },
            {
              title: "Halo",
              link: "/engagement-rings/?style=Halo",
            },
            {
              title: "East West",
              link: "/engagement-rings/?style=East West",
            },
            {
              title: "Half Bezel",
              link: "/engagement-rings/?style=Half Bezel",
            },
            {
              title: "Five Stone",
              link: "/engagement-rings/?style=Five Stone",
            },
            {
              title: "Cluster",
              link: "/engagement-rings/?style=Cluster",
            },
            {
              title: "Split Shank",
              link: "/engagement-rings/?style=Split Shank",
            },
            {
              title: "Pavé",
              link: "/engagement-rings/?style=Pave",
            },
          ],
        },
      ],
    },
    {
      heading: "Wedding",
      link: "/wedding-rings/",
    },
    {
      heading: "Diamonds",
      subSections: [
        {
          subHeading: "By Shape",
          viewAll: "/diamonds/",
          subSection: width < 768 ? 2 : width < 1024 ? 3 : 4,
          links: [
            {
              title: "Round",
              link: "/diamonds/round-cut",
            },
            {
              title: "Oval",
              link: "/diamonds/oval-cut",
            },
            {
              title: "Emerald",
              link: "/diamonds/emerald-cut",
            },
            {
              title: "Cushion",
              link: "/diamonds/?show_grid=true&shape=Cushion",
            },
            {
              title: "Elongated Cushion",
              link: "/diamonds/?show_grid=true&shape=Elongated Cushion",
            },
            {
              title: "Asscher",
              link: "/diamonds/asscher-cut",
            },
            {
              title: "Radiant",
              link: "/diamonds/radiant-cut",
            },
            {
              title: "Pear",
              link: "/diamonds/?show_grid=true&shape=Pear",
            },
            {
              title: "Marquise",
              link: "/diamonds/marquise-cut",
            },
            {
              title: "Princess",
              link: "/diamonds/princess-cut",
            },
            {
              title: "Rose-Cut",
              link: "/diamonds/rose-cut",
            },
            {
              title: "Old-European",
              link: "/diamonds/?show_grid=true&shape=Old European",
            },
            {
              title: "Old-Mine",
              link: "/diamonds/?show_grid=true&shape=Old Mine",
            },
            {
              title: "Trillion",
              link: "/diamonds/trillion-cut",
            },
          ],
        },
        {
          subHeading: "By Type",
          viewAll: "/diamonds/",
          subSection: 1,
          links: [
            {
              title: "Lab Diamonds",
              link: "/diamonds/lab-grown-diamonds",
            },
            {
              title: "Natural Diamonds",
              link: "/diamonds/natural-diamonds",
            },
            {
              title: "Moissanite",
              link: "/diamonds/moissanite-rings",
            },
            {
              title: "Fancy Color Diamonds",
              link: "/diamonds/fancy-color-diamonds",
            },
            {
              title: "Guaranteed Brilliant Lab Diamonds",
              link: "/guaranteed-brilliant",
            },
            {
              title: "Recycled Diamonds",
              link: "/diamonds/recycled-diamonds",
            },
          ],
        },
      ],
    },
    {
      heading: "Jewelry",
      link: "/earrings/",
      subSections: [
        {
          subHeading: "",
          subSection: 1,
          links: [
            {
              title: "Gift Guide",
              link: "/gift-guide",
            },
            {
              title: "Earrings",
              link: "/earrings/diamond-stud-earrings/",
            },
            {
              title: "Necklaces",
              link: "/necklaces/diamond-pendant-necklace/",
            },
            {
              title: "Tennis Bracelets",
              link: "/bracelets/tennis-bracelets/",
            },
            {
              title: "Gift Card",
              link: "/gift-card/",
            },
          ],
        },
      ],
    },
    {
      heading: "Appointments",
      subSections: [
        {
          subHeading: "",
          subSection: 1,
          links: [
            {
              title: "Visit our NYC Showroom",
              link: `/consultation/?scroll_target=${
                width < 768
                  ? "in-person&location=NYC Tribeca"
                  : "consultation-form-section&location=NYC Tribeca"
              }`,
            },
            {
              title: "Visit our San Francisco Showroom",
              link: `/consultation/?scroll_target=${
                width < 768
                  ? "in-person&location=San Francisco"
                  : "consultation-form-section&location=San Francisco"
              }`,
            },
            {
              title: "Visit our Washington DC Showroom",
              link: `/consultation/?scroll_target=${
                width < 768
                  ? "in-person&location=Washington DC"
                  : "consultation-form-section&location=Washington DC"
              }`,
            },
            {
              title: "Visit our Chicago Showroom",
              link: `/consultation/?scroll_target=${
                width < 768
                  ? "in-person&location=Chicago"
                  : "consultation-form-section&location=Chicago"
              }`,
            },
            {
              title: "Visit our Los Angeles Showroom",
              link: `/consultation/?scroll_target=${
                width < 768
                  ? "in-person&location=Los Angeles"
                  : "consultation-form-section&location=Los Angeles"
              }`,
            },
            {
              title: "Visit our Boston Showroom",
              link: `/consultation/?scroll_target=${
                width < 768
                  ? "in-person&location=Boston"
                  : "consultation-form-section&location=Boston"
              }`,
            },
            {
              title: "Book a Virtual Appointment",
              link: `/consultation/?scroll_target=${
                width < 768 ? "virtual" : "consultation-form-section"
              }`,
            },
          ],
        },
      ],
    },
    {
      heading: "About",
      subSections: [
        {
          subHeading: "",
          subSection: 1,
          links: [
            {
              title: "Our Story",
              link: "/about/",
            },
            {
              title: "Our Showroom",
              link: "/consultation/?scroll_target=consultation-form-section",
            },
            {
              title: "Ethical Sourcing",
              link: "/etiquette/",
            },
          ],
        },
      ],
    },
    {
      heading: "Education",
      link: "/etiquette/",
    },
    {
      heading: "Diamond Comparison",
      link: "/comparison/",
      mobileOnly: true,
    },
    {
      heading: "Help",
      link: "/contact/",
    },
  ];

  return (
    <div className="header-wrapper">
      <Header
        navData={navData}
        onCartClick={() => {
          setShowCart({ value: true, showId: "" });
        }}
        onSearchClick={onSearchClick}
        cartItems={size(cart.products)}
        faveTotalItems={Number(totalItems)}
        {...headerProps}
      />
    </div>
  );
};

HeaderWrapper.propTypes = {
  headerProps: PropTypes.shape({
    /**
     * Header main links
     */
    links: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * Link text
         */
        text: PropTypes.string,
        /**
         * Href for links
         */
        href: PropTypes.string,
      })
    ),
    /*
     * Number of items added to the cart
     */
    cartItems: PropTypes.number,
  }),
};

export default HeaderWrapper;
