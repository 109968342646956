import React from "react";
import PropTypes from "prop-types";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import findIndex from "lodash/findIndex";
import MissingItem from "./CartMissingItem";
import RingSetting from "./CartRingSetting";
import StudSetting from "./CartStudSetting";

import DiamondSetting from "./CartDiamondSetting";
import { CloseIcon } from "../_helpers/Icons/miscellaneousIcons";
import { useRouter } from "next/router";
import { bareMetalNameToSymbol } from "../services/dataConverter";
import useDialog from "../hooks/useDialog";
import { useCart } from "../services/cart";
import useLocalePrice from "../hooks/useLocalePrice";
import { getRakutenParams, isCustomDiamond } from "../_helpers/functions";

const CartMainItem = ({
  ringDetail,
  studDetail,
  braceletDetail,
  giftCardDetail,
  diamondDetail,
  hasDiamond,
  needDiamond,
  needRing,
  onRemove,
  primaryType,
  setShowCart,
  haveSoldDiamonds,
}) => {
  const router = useRouter();
  const { dialogType } = useDialog();
  const is_necklace = ringDetail?.hasOwnProperty("necklace_detail");
  const detailKey = is_necklace ? "necklace_detail" : "ring_detail";
  const { cart, updateCart } = useCart();
  const { formatPrice } = useLocalePrice();

  const editSetting = (ringData, type) => {
    router.push(
      {
        pathname: `/${
          includes(ringData[detailKey].setting, "Wedding_Band") ||
          includes(ringData[detailKey].setting, "Eternity_Band")
            ? "wedding-rings"
            : is_necklace
            ? "necklaces/diamond-pendant-necklace"
            : "engagement-rings"
        }/${ringData[detailKey].handle}`,
        query: {
          colorkey:
            bareMetalNameToSymbol[ringData.variant.option1.toLowerCase()],
          id: ringData.id,
          variant_id: ringData.variant.id,
          ring_size: ringData[detailKey].ring_size,
          engrave: ringData[detailKey].engrave,
          // need to add diamond type too
          mode: "edit",
          ...(ringData["ring_detail"]?.band_width
            ? { band_width: ringData["ring_detail"].band_width }
            : ""),
          ...(ringData["ring_detail"]?.diamond_type
            ? { diamond_type: ringData["ring_detail"].diamond_type }
            : ""),
          ...(ringData.diamond_id ? { diamond_id: ringData.diamond_id } : ""),
          ...(dialogType.bottom && router.query && router.query.ranMID
            ? getRakutenParams
            : {}),
        },
      },
      undefined,
      { shallow: false }
    );
    setShowCart({ value: false, showId: "" });
  };

  const editStud = (studData) => {
    router.push(
      {
        pathname: `/earrings/diamond-stud-earrings/${studData.stud_detail.handle}`,
        query: {
          colorkey:
            bareMetalNameToSymbol[studData.variant.option1.toLowerCase()],
          id: studData.id,
          variant_id: studData.variant.id,
          carat_weight: studData.variant.option2,
          // need to add diamond type too
          mode: "edit",
          ...(dialogType.bottom && router.query && router.query.ranMID
            ? getRakutenParams
            : {}),
        },
      },
      undefined,
      { shallow: false }
    );
    setShowCart({ value: false, showId: "" });
  };

  const editBracelet = (braceletData) => {
    router.push(
      {
        pathname: `/bracelets/tennis-bracelets/${braceletData.bracelet_detail.handle}`,
        query: {
          colorkey:
            bareMetalNameToSymbol[braceletData.variant.option1.toLowerCase()],
          id: braceletData.id,
          variant_id: braceletData.variant.id,
          bracelet_length: braceletData.variant.option2,
          // need to add diamond type too
          mode: "edit",
          ...(dialogType.bottom && router.query && router.query.ranMID
            ? getRakutenParams
            : {}),
        },
      },
      undefined,
      { shallow: false }
    );
    setShowCart({ value: false, showId: "" });
  };

  const editGiftCard = (giftCardData) => {
    router.push(
      {
        pathname: `/gift-card`,
        query: {
          id: giftCardData.id,
          variant_id: giftCardData.variant.id,
          amount: giftCardData.variant.option1,
          // need to add diamond type too
          mode: "edit",
          ...(dialogType.bottom && router.query && router.query.ranMID
            ? getRakutenParams
            : {}),
        },
      },
      undefined,
      { shallow: false }
    );
    setShowCart({ value: false, showId: "" });
  };

  const changeSetting = (ringData) => {
    router.push(
      {
        pathname: `/ring-style`,
        query: {
          shape: !isEmpty(diamondDetail)
            ? diamondDetail.diamond_detail["Shape"]
            : "",
          id: ringData.id,
          variant_id: ringData.variant.id,
          diamond_id: ringData.diamond_id,
          ring_size: ringData.ring_detail.ring_size,
          engrave: ringData.ring_detail.engrave,
          // need to add diamond type too
          mode: "change",
          ...(dialogType.bottom && router.query && router.query.ranMID
            ? getRakutenParams
            : {}),
        },
      },
      undefined,
      { shallow: false }
    );
    setShowCart({ value: false, showId: "" });
  };

  const modifyCartObject = (diamondData) => {
    if (cart?.products?.length) {
      const diamondIndex = findIndex(
        cart?.products,
        ({ type, id }) => type === "diamond" && id === diamondData?.diamond_id
      );
      const ringIndex = findIndex(
        cart?.products,
        ({ type, id }) => type === "ring" && id === diamondData?.id
      );
      const newCartObj = { products: [] };

      for (const [idx, cartProd] of cart.products.entries()) {
        const returnObj = cloneDeep(cartProd);
        if (idx == diamondIndex) {
          returnObj.preference = "secondary";
        } else if (idx == ringIndex) {
          returnObj.preference = "primary";
        }
        newCartObj.products.push(returnObj);
      }
      updateCart(newCartObj);
    }
  };

  const changeDiamond = (diamondData, flag = false) => {
    if (flag) {
      modifyCartObject(diamondData);
    }
    router.push(
      {
        pathname: `/diamonds`,
        query: {
          shape: !isEmpty(diamondData)
            ? includes(diamondData[detailKey].stone, "Round Brilliant")
              ? "Round"
              : diamondData[detailKey].stone
            : "",
          id: diamondData.diamond_id,
          [is_necklace ? "necklace_id" : "ring_id"]: diamondData.id,
          ring_size: diamondData[detailKey].ring_size,
          engrave: diamondData[detailKey].engrave,
          // need to add diamond type too
          mode: "change",
          ...(dialogType.bottom && router.query && router.query.ranMID
            ? getRakutenParams
            : {}),
        },
      },
      undefined,
      { shallow: false }
    );
    setShowCart({ value: false, showId: "" });
  };

  return (
    <div className="border-b border-t border-theme-blue pt-6 px-7 pb-14">
      <div
        className={`pb-1 w-full ${hasDiamond || !needDiamond ? "mt-1.5" : ""}`}
      >
        <div className="flex justify-between items-center w-full text-lg 3xl:text-2xl 4xl:text-3xl leading-snug 3xl:leading-tight font-sans 3xl:mb-9">
          <div className="flex items-center">
            <span
              onClick={() => {
                if (ringDetail && diamondDetail) {
                  onRemove({
                    diamondId: diamondDetail.diamond_detail["Diamond ID"],
                    [is_necklace ? "necklaceId" : "ringId"]: ringDetail.id,
                  });
                } else if (diamondDetail) {
                  onRemove({ id: diamondDetail.id });
                } else if (studDetail) {
                  onRemove({ id: studDetail.id });
                } else if (braceletDetail) {
                  onRemove({ id: braceletDetail.id });
                } else if (giftCardDetail) {
                  onRemove({ id: giftCardDetail.id });
                } else {
                  onRemove({ id: ringDetail.id });
                }
              }}
              className="mr-1.5 cursor-pointer"
            >
              <CloseIcon className="w-6 h-6" />
            </span>
            {/* This will be primary name */}
            <p
              className={`font-serif ${
                primaryType === "loose" ? "" : "uppercase"
              } w-36`}
            >
              {is_necklace
                ? "Your necklace"
                : !isEmpty(braceletDetail)
                ? "Your Bracelet"
                : !isEmpty(giftCardDetail)
                ? "Your Gift Card"
                : !isEmpty(studDetail)
                ? "Your Stud"
                : primaryType === "loose"
                ? `${diamondDetail.diamond_detail["Carat"]} Carat ${diamondDetail.diamond_detail["Shape"]} ${diamondDetail.diamond_detail["Type"]} Diamond`
                : "Your ring"}
            </p>
          </div>
          <p>
            {!(
              haveSoldDiamonds.status &&
              haveSoldDiamonds.ids.includes(diamondDetail?.diamond_id)
            )
              ? !isEmpty(studDetail)
                ? `${formatPrice(
                    Number(studDetail ? Number(studDetail.variant.price) : 0)
                  )}`
                : !isEmpty(braceletDetail)
                ? `${formatPrice(
                    Number(
                      braceletDetail
                        ? Number(braceletDetail?.variant?.price)
                        : 0
                    )
                  )}`
                : !isEmpty(giftCardDetail)
                ? `${formatPrice(
                    Number(
                      giftCardDetail
                        ? Number(giftCardDetail?.variant?.price)
                        : 0
                    )
                  )}`
                : `${formatPrice(
                    Number(
                      (ringDetail ? Number(ringDetail.variant.price) : 0) +
                        (diamondDetail
                          ? Number(diamondDetail.diamond_detail["Cost"])
                          : 0)
                    )
                  )}`
              : "SOLD"}
          </p>
        </div>
      </div>
      <div
        className={`flex ${
          primaryType === "primary" ? "flex-col" : "flex-col-reverse"
        }`}
      >
        {!isEmpty(studDetail) ? (
          <StudSetting
            id={`cart-${studDetail.id}-${studDetail.stud_detail.ring_size}-`}
            image={studDetail.variant.image.src}
            name={studDetail.stud_detail.title}
            description={`${studDetail.variant.option1}`}
            caratWeight={studDetail.variant.option2}
            diamondType={studDetail.variant.option3}
            isChangeble={false}
            onEditSetting={() => editStud(studDetail)}
            studId={`${studDetail.id}`}
          />
        ) : null}

        {!isEmpty(braceletDetail) ? (
          <StudSetting
            id={`cart-${braceletDetail.id}`}
            image={braceletDetail.variant.image.src}
            name={braceletDetail.bracelet_detail.title}
            description={`${braceletDetail.variant.option1}`}
            braceletLength={braceletDetail.variant.option2}
            diamondType={braceletDetail.variant.option3}
            isChangeble={false}
            onEditSetting={() => editBracelet(braceletDetail)}
            studId={`${braceletDetail.id}`}
          />
        ) : null}

        {!isEmpty(giftCardDetail) ? (
          <StudSetting
            id={`cart-${giftCardDetail.id}`}
            image={giftCardDetail.variant.image.src}
            name={giftCardDetail.gift_card_details.title}
            amount={`${giftCardDetail.variant.option1}`}
            isChangeble={false}
            onEditSetting={() => editGiftCard(giftCardDetail)}
            studId={`${giftCardDetail.id}`}
          />
        ) : null}

        {!isEmpty(ringDetail) ? (
          <RingSetting
            id={`cart-${ringDetail.id}-${ringDetail[detailKey].ring_size}-${
              ringDetail[detailKey].engrave
            }${ringDetail.diamond_id ? `-${ringDetail.diamond_id}` : ""}`}
            image={ringDetail.variant.image.src}
            name={ringDetail[detailKey].title}
            description={`${ringDetail.variant.option1}`}
            size={ringDetail[detailKey].ring_size}
            engrave={ringDetail[detailKey].engrave}
            settingPrice={Number(ringDetail.variant.price).toFixed(2)}
            hasStone={ringDetail[detailKey].stone !== "None"}
            diamondType={ringDetail.variant.option2}
            isChangeble={ringDetail.preference === "secondary"}
            onEditSetting={(editType) => editSetting(ringDetail, editType)}
            onChangeSetting={() => changeSetting(ringDetail)}
            ringId={`${ringDetail.id}`}
            isNecklace={is_necklace}
            bandWidth={ringDetail.variant.option3}
          />
        ) : null}
        {!isEmpty(diamondDetail) ? (
          <>
            <DiamondSetting
              id={`${
                !isEmpty(ringDetail) ? ringDetail.diamond_id : diamondDetail.id
              }`}
              image={diamondDetail.diamond_detail.image}
              name={`${
                diamondDetail.diamond_detail["Shape"] ||
                diamondDetail.diamond_detail["shape"]
              } ${!hasDiamond ? "diamond" : ""}`}
              cut={
                diamondDetail.diamond_detail["Cut Grade"] ||
                diamondDetail.diamond_detail["cut_grade"]
              }
              carat={
                diamondDetail.diamond_detail["Carat"] ||
                diamondDetail.diamond_detail["carat"]
              }
              color={
                diamondDetail.diamond_detail["Color Name"] ||
                diamondDetail.diamond_detail["color"]
              }
              clarity={
                diamondDetail.diamond_detail["Clarity"] ||
                diamondDetail.diamond_detail["clarity"]
              }
              settingPrice={Number(
                diamondDetail.diamond_detail["Cost"] ||
                  diamondDetail.diamond_detail["cost"]
              ).toFixed(2)}
              isChangeble={
                !isEmpty(ringDetail) && diamondDetail.preference === "primary"
              }
              onChangeDiamond={(flag) => changeDiamond(diamondDetail, flag)}
              diamondDetail={diamondDetail}
              setShowCart={setShowCart}
              haveSoldDiamonds={haveSoldDiamonds}
            />
            {isCustomDiamond(diamondDetail.diamond_id) ? (
              <div className="w-full mt-3">
                <span className="text-theme-blue text-lg font-bold leading-7">
                  NOTE:{" "}
                </span>
                <span className="text-theme-blue text-lg font-medium leading-7">
                  Default diamond stone has been chosen.
                </span>
              </div>
            ) : (
              ""
            )}
          </>
        ) : null}
      </div>

      {needDiamond || needRing ? (
        <MissingItem
          productId={needDiamond ? ringDetail.id : diamondDetail.id}
          ringSize={needDiamond ? ringDetail[detailKey].ring_size : ""}
          engraving={needDiamond ? ringDetail[detailKey].engrave : ""}
          missingDiamond={needDiamond}
          isNecklace={is_necklace}
          ringShape={
            !isEmpty(diamondDetail) ? diamondDetail.diamond_detail["Shape"] : ""
          }
          diamondSetting={
            !isEmpty(ringDetail)
              ? includes(ringDetail[detailKey].stone, "Round Brilliant")
                ? "Round"
                : ringDetail[detailKey].stone
              : ""
          }
        />
      ) : null}
    </div>
  );
};

CartMainItem.propTypes = {
  ringDetail: PropTypes.shape({
    id: PropTypes.number,
    variant: PropTypes.shape({
      option1: PropTypes.string,
      option2: PropTypes.string,
      price: PropTypes.string,
      image: PropTypes.shape({
        src: PropTypes.string,
      }),
    }),
    ring_detail: PropTypes.shape({
      title: PropTypes.string,
      ring_size: PropTypes.number,
      engrave: PropTypes.string,
      tags: PropTypes.shape({
        stone: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      }),
    }),
    preference: PropTypes.string,
    diamond_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  diamondDetail: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    diamond_detail: PropTypes.shape({
      image: PropTypes.string,
      ["Shape"]: PropTypes.string,
      ["Cost"]: PropTypes.string,
      ["Cut Grade"]: PropTypes.string,
      ["Color Name"]: PropTypes.string,
      ["Clarity"]: PropTypes.string,
      ["Carat"]: PropTypes.string,
      ["Type"]: PropTypes.string,
      ["Diamond ID"]: PropTypes.string,
    }),
    preference: PropTypes.string,
  }),
  hasDiamond: PropTypes.bool,
  needDiamond: PropTypes.bool,
  needRing: PropTypes.bool,
  onRemove: PropTypes.func,
  primaryType: PropTypes.string,
  setShowCart: PropTypes.func,
  haveSoldDiamonds: PropTypes.shape({
    status: PropTypes.bool,
    ids: PropTypes.array,
  }),
};

export default CartMainItem;
