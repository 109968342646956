import React, { useState, useEffect } from "react";
import useDialog from "../../hooks/useDialog";
import QuizModal from "../../stories/QuizModal";
// import VaultModal from "../../stories/VaultModal";
// import GiveawayModal from "../../stories/GiveawayModal";

/**
 * Modal Wrapper
 * @return {node}
 */
const ModalWrapper = () => {
  const [showModal, setShowModal] = useState(false);
  const { dialogType, setDialogType } = useDialog();

  useEffect(() => {
    /**
     * Scroll event listener
     */
    function listenScrollEvent() {
      if (window.scrollY / document.body.clientHeight > 0.35) {
        setShowModal(true);
        window.removeEventListener("scroll", listenScrollEvent);
      }
    }
    if (
      dialogType.quizModal &&
      dialogType.quizModal.shownTimes < 4 &&
      dialogType.quizModal.showAgain < Date.now()
    ) {
      window.addEventListener("scroll", listenScrollEvent);
    }
    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, [dialogType]);

  /**
   * To clear the dialog data
   */
  const clearDialogData = () => {
    setDialogType({
      ...dialogType,
      quizModal: {
        shownTimes: dialogType.quizModal.shownTimes + 1,
        // 3 days (time in millisecond)
        showAgain: Date.now() + 1000 * 60 * 60 * 24 * 3,
      },
    });
    setShowModal(false);
  };
  // QuizModal will be the default Modal
  return <QuizModal open={showModal} handleClose={() => clearDialogData()} />;
};

export default ModalWrapper;
